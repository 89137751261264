.sidebar {
    background-color: #4E5D71 !important;
    background-color: #4E5D71 !important;
}

#sidebar {
    font-size: 1.3em !important;
}


