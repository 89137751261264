.appbar{
  background: #fff;
  color: #4E5D71;
  box-shadow: none;
}

#header{
    color: #4E5D71;
    background: white;
}

@media all and (max-width: 720px) {
    .accountIcon {
      display: none;
    }
  }

#helpDrawer{
  z-index: 9999 !important;
  background: white;
}