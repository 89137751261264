.stepIcon,
.stepNumber {
  font-weight: bold;
}

.stepInactive {
  color: #4e5d71 !important;
}

.stepActive {
  color: #58b29f !important;
}

.stepCompleted {
  color: #58b29f !important;
}
