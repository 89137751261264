.appbar{
  background: #fff;
  color: #4E5D71;
  box-shadow: none;
}

#header{
    color: #fdd343;
    background: white;
}

@media all and (max-width: 720px) {
    .accountIcon {
      display: none;
    }
  }

#helpDrawer{
  z-index: 9999 !important;
  background: white;
}

.accounticon{
  color: #fdd343;
}

.helpicon{
  color: #fdd343;
}

.icontext{
  color: #000;
}